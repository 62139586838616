<script setup>
import Card from '@/components/Card.vue'
const props = defineProps({
  articles: {
    type: Array,
    default: () => []
  }
})

const currentItem = ref(0)
const timer = reactive({})

function resetTimer (n) {
  clearTimeout(timer.value)
  currentItem.value = n
  timer.value = setTimeout(() => {
    nextItem()
  }, 5000)
}

function nextItem () {
  const N = currentItem.value === props.articles.length - 1 ? 0 : currentItem.value + 1
  resetTimer(N)
}

function prevItem () {
  const N = currentItem.value === 0 ? props.articles.length - 1 : currentItem.value - 1
  resetTimer(N)
}

onMounted(() => {
  resetTimer(0)
})

</script>

<template>
  <div class="carousel relative bg-transparent w-full mb-6 sm:mb-12">
    <template v-for="(article, index) in articles" :key="article.attributes.slug">
      <div class="carousel-inner relative overflow-hidden w-full">
        <div>
          <input class="carousel-open" type="radio" :id="`carousel-${index}`" name="carousel" aria-hidden="true" hidden="" :checked="currentItem === index">
          <div class="carousel-item absolute opacity-0">
            <Card :article="article" class="mb-12 block"></Card>
          </div>
          <ol class="carousel-indicators flex flex-row justify-center">
            <li v-for="(_, bulletIndex) in articles" :key="`bullet-${bulletIndex}`">
              <label :for="`carousel-${bulletIndex}`" :class="index === bulletIndex ? 'text-[#bcbcbc]' : 'text-[#e0e0e0]'" class="carousel-bullet cursor-pointer block text-4xl text-black hover:text-mnet mx-0.5">•</label>
            </li>
          </ol>
        </div>
      </div>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.carousel-open:checked + .carousel-item {
  position: static;
  opacity: 100;
}
.carousel-item {
  -webkit-transition: opacity 0.6s ease-out;
  transition: opacity 0.6s ease-out;
}
.carousel-open:checked ~ .controls {
  display: flex;
}
.carousel-indicators {
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  bottom: 2%;
  left: 0;
  right: 0;
  text-align: center;
  display: none;
}
.carousel-open:checked ~ .carousel-indicators {
  display: flex;
}
</style>
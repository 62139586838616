<script setup>
import ChevronRightIcon from '@/components/icons/ChevronRightIcon.vue'
import StopwatchIcon from '@/components/icons/StopwatchIcon.vue'
import Image from '@/components/Image.vue'
import { getReadingTime } from '@/utils/text.js'

const props = defineProps({
  article: {
    required: true,
    type: Object
  }
})

const title = props.article.attributes.title
const coverObj = props.article.attributes.cover.data.attributes

const tagList = computed(() => {
 return props.article.attributes.tag.data.map(t => t.attributes.name)   
})

const readingTime = computed (() => {
  const time = getReadingTime(props.article.attributes.content)
  return `${time} ${ time > 1 ? 'minuti' : 'minuto'}`
})

</script>

<template>
  <NuxtLink :to="`/blog/articles/${article.attributes.slug}/`">
    <div class="article-container h-full">
      <div class="article shadow-xl rounded-md h-full">
        <Image
          :content="coverObj"
          class-list="w-full h-60 sm:h-56 lg:h-64 xl:h-72 cover object-top object-cover"
          :alt="title"
        />
        <div class="py-4 px-8 flex flex-col justify-between h-full">
          <p class="text-sm sm:text-lg text-gray-mid-light font-semibold mb-4">
            <template v-for="tag in tagList" :key="tag">
              &#35;{{ tag }}
            </template>
          </p>
          <h3 class="text-[26px] lg:text[32px] font-family-raleway font-bold mb-2">{{ title }}</h3>
          <p class="leading-6 mb-8 sm:text-lg lg:text-xl lg:leading-7 sm:line-clamp-4">{{ article.attributes.summary }}</p>
          <div class="my-4 flex justify-between text-[15px] lg:text-lg items-end">
            <div class="flex flex-col items-center">
              <StopwatchIcon class="text-primary"/>
              <span>{{ readingTime }}</span>
            </div>
            <div class="inline-flex text-primary items-center">
              <span class="font-semibold underline mr-2">Leggi articolo</span>
              <ChevronRightIcon/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </NuxtLink>
</template>

<style lang="scss" scoped>
.article-container {
  container: article / inline-size;
}

.article {
  display: flex;
  flex-direction: column;
  :deep(.cover) {
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
  }
}

@container (min-width: 600px) {
 .article {
  display: grid;
  grid-template-columns: 1fr 1fr;
  picture {
    order: 2;
  }
  :deep(.cover) {
    border-top-left-radius: 0;
    border-bottom-right-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
    height: 100%;
  }
 }
}
</style>
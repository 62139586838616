import { useStore } from '@/store/index.js'
import { storeToRefs } from 'pinia'

export const useArticlesByTag = (tag = '') => {
  const store = useStore()
  const { articles, limit } = storeToRefs(store)

  const articlesByTag = articles.value.filter((a) => tag === '' || a.attributes.tag.data.some(t => t.attributes.slug === tag))
  const highlightedArticles = articlesByTag.filter(a => a.attributes.highlight)
  const restOfArticles = articlesByTag.filter(a => !a.attributes.highlight)

  const articlesToShow = computed(() => {
    return restOfArticles.slice(0, limit.value)
  })
  return { articles: articlesByTag, highlightedArticles, restOfArticles: articlesToShow, totRestOfArticles: restOfArticles.length }
}
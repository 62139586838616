<template>
  <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Desktop_filtroOn" transform="translate(-652.000000, -537.000000)">
        <g id="Group-9" transform="translate(148.000000, 215.000000)">
          <g id="Group-3-Copy-2" transform="translate(394.000000, 320.000000)">
            <g id="expand_more_FILL0_wght400_GRAD0_opsz48" transform="translate(120.000000, 12.000000) rotate(-90.000000) translate(-120.000000, -12.000000) translate(110.000000, 2.000000)">
              <polygon id="Path" fill="currentColor" fill-rule="nonzero" points="10 14.5 3.5 7.7155477 4.66458333 6.5 10 12.0971731 15.3354167 6.52826855 16.5 7.74381625"></polygon>
              <circle id="Oval" stroke="currentColor" stroke-width="1.5" cx="10" cy="10" r="9.25"></circle>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>